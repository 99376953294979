import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import blogData from './blogData'; 

const BlogTitles = () => {
  const { category } = useParams();
  const [selectedBlog, setSelectedBlog] = useState(null);

  const blogTitles = blogData[category] || [];

  const handleCardClick = (blog) => {
    setSelectedBlog(blog);
  };

  const closeModal = () => {
    setSelectedBlog(null);
  };

  return (
    <Container>
      <Title>{category}</Title>
      <BlogContainer>
        {blogTitles.map((blog, index) => (
          <BlogCardWrapper key={index} onClick={() => handleCardClick(blog)}>
            <BlogCard>
              <BlogCardTitle>{blog.title}</BlogCardTitle>
              <BlogCardIntroduction>{blog.introduction}</BlogCardIntroduction>
            </BlogCard>
          </BlogCardWrapper>
        ))}
      </BlogContainer>

      {selectedBlog && (
        <ModalBackground onClick={closeModal}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalTitle>{selectedBlog.title}</ModalTitle>
            <ModalDescription>{selectedBlog.description}</ModalDescription>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </Modal>
        </ModalBackground>
      )}
    </Container>
  );
};

export default BlogTitles;

// Styled components
const Container = styled.div`
  padding: 20px;
  margin-top: 10vh;

  @media (max-width: 768px) {
    margin-top: 10vh;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin-top: 15vh;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 24px;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
`;

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const BlogCardWrapper = styled.div`
  width: 100%;
  max-width: 50vw;
  cursor: pointer;

  @media (max-width: 768px) {
    max-width: 400px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const BlogCard = styled.div`
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, background-color 0.2s;

  &:hover {
    transform: translateY(-5px);
    background-color: #f9f9f9;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const BlogCardTitle = styled.h2`
  margin: 0;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const BlogCardIntroduction = styled.p`
  margin: 10px 0 0;
  font-size: 14px;
  color: #555;

  @media (max-width: 768px) {
    margin: 8px 0 0;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    margin: 6px 0 0;
    font-size: 12px;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 70vw;
  width: 100%;
  max-height: 80vh; /* Set max height to 80vh */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 15px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
  }
`;

const ModalTitle = styled.h2`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    margin-bottom: 6px;
    font-size: 16px;
  }
`;

const ModalDescription = styled.div`  // Changed to div to accommodate nested elements
  margin-bottom: 20px;
  p {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
  }
  h3 {
    font-size: 20px;
    color: #333;
  }
  h4 {
    font-size: 18px;
    color: #666;
  }
  ul, ol {
    margin-left: 20px;
  }
  li {
    margin-bottom: 10px;
  }
  // Add more styling as needed
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #FF0069;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #FF4B8A;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
  }

  @media (max-width: 480px) {
    padding: 6px 10px;
  }
`;
